@import 'styles/variables/variables.scss';

.form-input {
  display: flex;
  width: 100%;
  margin: $base-margin 0;

  input {
    width: 100%;
    background-color: $form-element-color;
    border: 1px solid $form-element-color;
    padding: 12px 14px;
    color: $form-element-text-color;
    border-radius: 3px;
    font-size: $base-font-size;
    line-height: 1;
  }

  ::placeholder {
    color: $form-element-placeholder-color;
  }
}