// TODO put styles....
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p,
li {
  line-height: 1.5;
}

.left-align {
  text-align: left;
}
