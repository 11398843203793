@import 'styles/variables/colors.scss';

.page-loader {
  background: $darker-purple-color;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  img {
    width: 75px;
  }
}
