@import 'typography';
@import 'button';
@import 'input';

html {
  min-height: 100vh;
}

html, body {
  margin: 0;
}

a {
  text-decoration: none;
}