.maintenance-page {
  text-align: center;
  margin: 100px 20px;
  font-family: Helvetica, Arial sans-serif;

  .header {
    margin-bottom: 40px;

    img {
      height: 64px;
    }
  }

  .content {
    max-width: 500px;
    margin: 0 auto;
  }
}
