@import 'styles/variables/colors.scss';
@import 'styles/variables/variables.scss';

.form-button {
  width: 100%;
  margin-top: $base-margin;

  button {
    width: 100%;
    border: none;
    font-weight: 700;
    padding: 12px;
    cursor: pointer;
    border-radius: 3px;
    font-size: $base-font-size;

    &:disabled {
      opacity: 0.5;
    }
  }

  &.primary-button {
    button {
      color: $inverse-primary-text-color;
      background-color: $primary-color;
    }
  }
}

.form-error {
  .form-button {
    margin-top: 24px;
  }
}