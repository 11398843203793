$base-background-color: #ffffff;

$primary-color: #00c4d2;
$primary-text-color: #6d5f79;
$inverse-primary-text-color: #ffffff;
$darker-purple-color: #1b1439;
$lighter-purple-color: #c2b6cb;

$border-color: #e4e0e5;
$box-shadow-color: #cccccc;

$form-element-color: #f1f0f2;
$form-element-text-color: #6d5f7a;
$form-element-placeholder-color: #c3bec9;
