$base-font-size: 14px;

$base-margin: 20px;

// breakpoints
$phone: 568px;
$phablet: 768px;
$tablet: 1024px;
$laptop: 1080px;
$desktop: 1366px;
